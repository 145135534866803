import { Redirect, Route } from 'react-router-dom';

import DashboardMain from '../dashboard/DashboardMain';
import LoginWrapper from '../auth/LoginWrapper';
import PropTypes from 'prop-types';
import React from 'react';

const LandingPageRouter = ({ user, triggerSessionValidation, setUserInfo , userInfo, web3, ethEnabled }) => {  

  return (
    <React.Fragment>
      <Route
        exact
        path="/login"
        render={() =>
          user ? <Redirect to="/" /> : <LoginWrapper user={user} triggerSessionValidation={triggerSessionValidation} setUserInfo={setUserInfo}/>
        }
      />
      <Route
        exact
        path="/"
        render={() =>
          user ? (
            <DashboardMain triggerSessionValidation={triggerSessionValidation} user={user} userInfo={userInfo} web3={web3} ethEnabled={ethEnabled}/>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/compras"
        render={() =>
          user ? (
            <DashboardMain triggerSessionValidation={triggerSessionValidation} user={user} selectedSelection={8} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      <Route
        exact
        path="/pago-finalizado/:billNumber"
        render={({ match }) => {
          return (
            <DashboardMain
              billNumber={match.params.billNumber}
              triggerSessionValidation={triggerSessionValidation}
              user={user}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

LandingPageRouter.propTypes = {
  user: PropTypes.object,
  triggerSessionValidation: PropTypes.func
};

export default LandingPageRouter;
