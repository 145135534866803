import {
  AccountBalance,
  FindInPage as FindInPageIcon,
  Group as GroupIcon,
  ExpandLess, ExpandMore,
  Settings as Settings,
  MonetizationOn,
  ShoppingCart,
  Store,
  Public,
  LocalAtm,
  Business
} from '@material-ui/icons';

import {
  AppBar,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Grid
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import LoadingBackdrop from '../common/LoadingBackdrop';
import Notifications from './appBar/Notifications';
import PropTypes from 'prop-types';
import Sockette from 'sockette';
import CopCoinLoad from './copcoinLoad/copcoinLoad';
import CopCoinRedeem from './copcoinRedeem/copcoinRedeem';
import CopcoinTransfer from './copcoinTransfer/copcoinTransfer';
import BuySell from './buy&sell/BuySell';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import CopcoinLogo from '../../logos/logo-copcoin.png';
import {balanceOf, totalSupply} from '../api/contractManager';
import {formatNumber} from '../utils/functions';
import Colors from '../../constants/Colors';
 

// import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

let drawerWidth = 240;

const DashboardMain = ({ triggerSessionValidation, user, billNumber, selectedSelection , userInfo, web3, ethEnabled}) => {

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  drawerWidth = isMobile ? 60 : 240;
  const classes = useStyles();

  const [selection, setSelection] = useState(3);
  const [websocket, setWebsocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openLoading, setOpenLoading] = useState(false);
  const [ammount, setAmmount] = useState(0);
  const [account, setAccount] = useState('');

  const handleMenuItemSelected = (itemIndex) => {
    setSelection(itemIndex);
  };

  const logout = async () => {
    setOpenLoading(true);
    await Auth.signOut({ global: true });
    setOpenLoading(false);
    triggerSessionValidation();
  };

  const getUserRoles = async () => {
    setRoles(user.signInUserSession.accessToken.payload['cognito:groups']);
  };

  const getAccount = async () => {
    if(web3.eth){
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
      if(accounts.length > 0){
        try{
          totalSupply(web3)
          .then( result => {
          });
        }
        catch(e){
          console.log(e);
        }
      }
    }
  }

  useEffect(() => {
    account && getBalance();
  }, [account]);

  useEffect(() => {
    user && getUserRoles();
  }, [user]);

  useEffect(() => {
    web3 && getAccount();
  }, [web3]);

  useEffect(() => {
    if (user && !websocket) {
      const socket = new Sockette(
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_WEBSOCKET_PROD
          : process.env.REACT_APP_WEBSOCKET_DEV,
        {
          timeout: 5e3,
          maxAttempts: 5e5,
          onopen: () => {
            socket.json({ action: 'registerConnection', clientId: String(user.username) });
          },
          onmessage: (event) => {
            const data = JSON.parse(event.data);
          },
          onerror: (error) => {
            console.log('ERROR', error);
          },
          onmaximum: (event) => console.log('Maximum Attempts', event),
          onclose: (event) => console.log('Closed', event)
        }
      );
      setWebsocket(socket);
    }
  }, [user, websocket]);

  const web3Check = () => {
    window.ethereum.on('accountsChanged', function (accounts) {
      getAccount();
    })
    ethEnabled();
  }

  const getBalance = () => {
    try{
      balanceOf(web3, account)
      .then(result => {
        setAmmount(parseFloat(result));
      });
    }
    catch(e){
      console.log(e);
    }
  }

  const markNotificationsAsRead = useCallback(() => {
    const readNotifications = notifications.map((notif) => {
      notif.notRead = false;
      return notif;
    });
    setNotifications([...readNotifications]);
  }, [notifications]);

  const selectDashboardTab = () => {
    switch (selection) {
      case 0:
        return null;
      case 2:
        return <BuySell open={true} />;
      case 3:
        return <CopCoinLoad />;
      case 4:
        return <CopCoinRedeem web3={web3} account={account} getBalance={getBalance}/>;
      case 5:
        return <CopcoinTransfer web3={web3} account={account} getBalance={getBalance}/>;
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <LoadingBackdrop open={openLoading} />
      <CssBaseline />
      <AppBar position='fixed' className={classes.appBar} color='secondary'>
        <Toolbar>
          <div color='inherit'>
            <img
              src={CopcoinLogo}
              alt='Logo Copcoin'
              width='200'
              height='auto'
              id='fmLogo'
            />
          </div>
          <div className={classes.titleText}>
            {user && user.attributes.name && !isMobile && <Typography>{user.attributes.name}</Typography>}
          </div>
          <Notifications notifications={notifications} markAsRead={markNotificationsAsRead} />
          <Button color='primary' className={classes.toolbarButtons} onClick={web3Check}>
            Conectar wallet
          </Button>
          <Button color='primary' className={classes.toolbarButtons} onClick={logout}>
            Cerrar sesión
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.toolbar} />
        <List>
        {roles.includes('admin') && (
            <ListItem button selected={selection === 2} onClick={() => handleMenuItemSelected(2)} id='usersButton'>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary='Comprar' />
            </ListItem>
          )}
          {roles.includes('admin') && (
            <ListItem button selected={selection === 3} onClick={() => handleMenuItemSelected(3)} id='usersButton'>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary='Cargar' />
            </ListItem>
          )}
          {roles.includes('admin') && (
            <ListItem button selected={selection === 4} onClick={() => handleMenuItemSelected(4)} id='usersButton'>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary='Liquidar' />
            </ListItem>
          )}
          {roles.includes('admin') && (
            <ListItem button selected={selection === 5} onClick={() => handleMenuItemSelected(5)} id='usersButton'>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary='Transferir' />
            </ListItem>
          )}
          <div className={classes.bottomPush}>
            <Grid className={classes.container}>
              <Grid item xs={6} md={6}>
                <Typography>Copcoin: </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography>{formatNumber(ammount)}</Typography>
              </Grid>
            </Grid>
          </div>
          {/* {(roles.includes('kyc') || roles.includes('carlaid')) && (
            <ListItem button selected={selection === 8} onClick={() => handleMenuItemSelected(8)} id="paymentButton">
              <ListItemIcon>
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Compras" />
            </ListItem>
          )} */}
        </List>
      </Drawer>
      {selectDashboardTab()}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  toolbarButtons: {
    marginLeft: '1%'
  },
  notificationsBadge: {
    marginLeft: 'auto'
  },
  toolbar: theme.mixins.toolbar,
  titleText: {
    textAlign: 'left',
    marginLeft: '2%'
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
}
}));

DashboardMain.propTypes = {
  triggerSessionValidation: PropTypes.func,
  user: PropTypes.object,
  billNumber: PropTypes.string,
  selectedSelection: PropTypes.number
};

export default DashboardMain;
