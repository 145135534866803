import './App.css';

import { BrowserRouter, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import Colors from './constants/Colors';
import LandingPage from '../src/components/landing-page/LandingPage';
import ScrollToTop from '../src/components/common/ScrollToTop';
import awsExports from './aws-exports-dev';
import { esES } from '@material-ui/core/locale';
import Web3 from 'web3';

Amplify.configure(awsExports);

const theme = createMuiTheme(
  {
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '1.25em'
        }
      }
    },
    palette: {
      primary: {
        main: Colors.primary
      },
      secondary: {
        main: '#EFEFEF'
      }
    },
    typography: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
      fontSize: 14
    },
    status: {
      danger: 'orange'
    }
  },
  esES
);

function App(props) {
  const [user, setUser] = useState();
  const [userInfo, setUserInfo] = useState();
  const [authEvent, setAuthEvent] = useState(false);
  const [web3, setWeb3] = useState({});

  /*const loadBlockchain = async function() {
    const web3Instance = new Web3(Web3.givenProvider || 'http://127.0.0.1:7545');
    setWeb3(web3Instance);
  }*/

  const verifySession = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      //console.log(authUser.signInUserSession.idToken.jwtToken);
      setUser(authUser);
    } catch (err) {
      console.log(err);
      setUser(null);
    }
  };

  const triggerSessionValidation = () => {
    setAuthEvent((previous) => !previous);
  };

  const ethEnabled = async () => {
    if (window.ethereum) {
      await window.ethereum.send('eth_requestAccounts');
      const newWeb3 = new Web3(window.ethereum);
      setWeb3(newWeb3);
    }
  }

  useEffect(() => {
    verifySession();
    //ethEnabled();
  }, [authEvent]);

  return (
    <div className='App'>
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop>
            <Route
              path='/'
              render={() => <LandingPage user={user} setUserInfo={setUserInfo} userInfo={userInfo} triggerSessionValidation={triggerSessionValidation}  web3={web3} ethEnabled={ethEnabled} />}
              {...props}
            />
          </ScrollToTop>
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
