import React, { useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { Auth } from 'aws-amplify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import LoadingBackdrop from '../common/LoadingBackdrop';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { validatePasswordInput } from '../../controllers/validators';

export default function ChangePassword({ triggerSessionValidation, localUser }) {
  const classes = useStyles();
  const [data, setData] = useState({ password: '', confirmPassword: '' });
  const [error, setError] = useState({ password: false, confirmPassword: false });
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openLoading, setOpenLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const existingErrors = () => {
    let exists = false;
    for (let key in error) {
      exists = exists || error[key];
    }
    return exists;
  };

  const updateData = async () => {
    if (!existingErrors()) {
      try {
        setOpenLoading(true);
        console.log(localUser)
        const result = await Auth.completeNewPassword(localUser, data.password);
        console.log(result);
        triggerSessionValidation();
      } catch (err) {
        setErrorMessage('Revisa que los campos estén correctos e intenta nuevamente.');
        setOpenError(true);
        console.log(err);
      } finally {
        setOpenLoading(false);
      }
    } else {
      setErrorMessage('Revisa que los campos estén correctos e intenta nuevamente.');
      setOpenError(true);
    }
  };

  const handleInput = (name, value) => {
    setData((previous) => {
      const newObject = { ...previous };
      newObject[name] = value;
      return newObject;
    });
    setError((previous) => {
      const newObject = { ...previous };
      let error = false;
      if (name === 'password') error = !validatePasswordInput(value);
      else if (name === 'confirmPassword') error = value !== data.password;
      newObject[name] = error;
      return newObject;
    });
  };

  return (
    <Container component='main' maxWidth='xs'>
      <LoadingBackdrop open={openLoading} />
      <div className={classes.paper}>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='error'>
            {errorMessage}
          </Alert>
        </Snackbar>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Actualizar Contraseña
        </Typography>
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          required
          name='password'
          label='Nueva Contraseña'
          type='password'
          id='password'
          error={error.password}
          helperText={error.password ? 'Debe tener por lo menos 8 caracteres, 1 mayúscula y 1 número' : null}
          value={data.password}
          onChange={(event) => handleInput(event.target.name, event.target.value)}
          autoComplete='new-password'
        />
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          required
          name='confirmPassword'
          label='Confirmar Nueva Contraseña'
          type='password'
          id='confirmPassword'
          error={error.confirmPassword}
          helperText={error.confirmPassword ? 'La contraseña no coincide con la especificada.' : null}
          value={data.confirmPassword}
          onChange={(event) => handleInput(event.target.name, event.target.value)}
          autoComplete='password'
        />
        <Button
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={() => {
            return updateData();
          }}
          id='submitButton'>
          Enviar
        </Button>
      </div>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

ChangePassword.propTypes = {
  triggerSessionValidation: PropTypes.func,
  localUser: PropTypes.object
};
