export const list = [
    {
        name: 'FM as a Service',
        link: 'https://financialomejor.com/fmaas',
        description: 'Obtén el mundo financiero bajo solo un API.'
    },
    {
        name: 'Digital Authentication',
        link: 'https://financialomejor.com/digital-authentication',
        description: 'Realiza un onboarding a tus clientes en tan solo unos minutos de manera 100% digital.'
    },
    {
        name: 'Digital Lending',
        link: 'https://financialomejor.com/digital-lending',
        description: 'Experiencia fluida que modifica la forma tradicional de solicitar un crédito, desde la originación hasta el cierre 100% digital.'
    },
    {
        name: 'Digital Documentation',
        link: 'https://financialomejor.com/digital-documentation',
        description: 'Elimina la necesidad de tener documentos, plantillas, pagarés y contratos físicos o que tus clientes tengan que firmar presencialmente la documentación requerida.'
    },
    {
        name: 'Digital Checks',
        link: 'https://financialomejor.com/digital-checks',
        description: 'Comienza a verificar antecedentes de tus usuarios ya sean personas naturales y jurídicas con el nivel más alto de biometría facial, validación de información y análisis de documentación en el mercado.'
    }
]
