import React, { useEffect, useState } from 'react';
import { deleteApi, getApi } from '../../api/apiManager';

import Colors from '../../../constants/Colors';
import ErrorSnackbar from '../../common/ErrorSnackbar';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import {
  Card , 
  CardContent ,
  TextField,
  Button,
  Container,
  Grid,
  Typography 
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SuccessSnackbar from '../../common/SuccessSnackbar';
import { makeStyles } from '@material-ui/core/styles';
import CopcoinLogoIcon from '../../../logos/icon-copcoin.png';
import { redeem } from '../../api/contractManager';
import { formatNumber } from '../../utils/functions';

export default function Redeem(props) {
  const web3 = props.web3;
  const account = props.account;
  const getBalance = props.getBalance;
  const classes = useStyles();
  const [redoFetch, setRedoFetch] = useState(false);
  const [ammount, setAmmount] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [transactionResponse, setTransactionResponse] = useState({responded:false})

  const toggleReDoFetch = () => {
    setRedoFetch(!redoFetch);
  };

  const handleCloseError = () => {
    setOpenError(false);
    setErrorMessage('');
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    setSuccessMessage('');
  };

  const resetForm = () => {
    setAmmount('');
  }

  const sendRedeem = async () => {
    try{
      redeem(web3 ,ammount, account)
      .then(response => {
        resetForm();
        getBalance();
        setTransactionResponse((previous) => {
          let newObject = {...previous};
          newObject.transaction = response;
          newObject.responded = true;
          return newObject;
        });
      })
      .catch(e => {
        console.log(e);
      });
    }
    catch(e){
      console.log(e);
    }
  }

  const back = () => {
    try{
      setTransactionResponse({
        responded:false
      })
    }
    catch(e){

    }
  }

  return (
    <div className={classes.root}>
      <Container component='main' maxWidth='md'>
        <div className={classes.paper}> 
          <LoadingBackdrop open={openBackdrop} />
          <ErrorSnackbar open={openError} message={errorMessage} handleClose={handleCloseError} />
          <SuccessSnackbar
            open={openSuccess}
            message={successMessage}
            handleClose={handleCloseSuccess}
            id='successSnackbar'
          />{console.log(transactionResponse)}
          {!transactionResponse.responded ? 
            <Grid container spacing={10} alignItems="center" justify="center" className={classes.container}>
              <Grid item xs={7}>
                <Card>
                  <CardContent>
                    <img
                        src={CopcoinLogoIcon}
                        alt='Logo Copcoin'
                        width='90'
                        height='auto'
                        id='homeButton'
                      />
                    <TextField
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      name='ammount'
                      label='Monto'
                      type='ammount'
                      id='ammount'
                      value={ammount}
                      onChange={(event) => setAmmount(event.target.value)}
                    />
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={() => {
                        sendRedeem();
                      }}
                      id='logInButton'>
                      Liquidar
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={5} >
                <Card>
                  <CardContent>
                    <Typography
                      margin='normal'
                      component='h5' variant='h5'
                    >
                      Resumen de compra
                    </Typography>
                    <Grid className={classes.container}>
                      <Grid item xs={8}>
                        <Typography
                          margin='normal'
                        >
                          Tasa:
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          Costos de compra:
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          Costos de transacción:
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          Gas requerido:
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          Costos financieros:
                        </Typography>
                        <Typography
                          margin='normal'
                          component='h6' variant='h6'
                        >
                          Total en COPC:
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          margin='normal'
                        >
                          0
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          0
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          0
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          0
                        </Typography>
                        <Typography
                          margin='normal'
                        >
                          0
                        </Typography>
                        <Typography
                          margin='normal'
                          component='h6' variant='h6'
                        >
                          0
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid> : 
          <Grid container spacing={10} alignItems="center" justify="center" className={classes.container}>
            <Grid item xs={10}>
              <Card>
                <CardContent>
                  <Typography
                    margin='normal'
                    component='h5' variant='h5'
                  >
                    Transacción {transactionResponse.transaction.status ? 'aprobada': 'rechazada'}
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    Dirección de origen:
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    {transactionResponse.transaction.from}
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    Dirección de destino:
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    {transactionResponse.transaction.to}
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    Hash de la transacción:
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    {transactionResponse.transaction.transactionHash}
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    Gas usado:
                  </Typography>
                  <Typography
                    margin='normal'
                  >
                    {formatNumber(transactionResponse.transaction.gasUsed)}
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.back}
                    onClick={() => {
                      back();
                    }}
                    id='logInButton'>
                    Volver
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
        </div>
      </Container>   
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: '1% 3% 8%',
    [theme.breakpoints.down('xs')]: {
      width: '82%'
    }
  },
  buttonsGroup: {
    margin: 'auto'
  },
  newUserButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  topButtons: {
    padding: '2% 0'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },

  container: {
    display:'flex', 
    justifyContent:'left'
  },

  buttonCreate: {
    marginBottom: '2%'
  },
  buttonDelete: {
    backgroundColor: Colors.accent,
    color: Colors.white
  },
  buttonEdit: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    marginRight: 10
  }
}));

Redeem.propTypes = {
  users: PropTypes.array
};
