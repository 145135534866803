import React, { useState } from 'react';

import Alert from '@material-ui/lab/Alert';
import { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import LoadingBackdrop from '../common/LoadingBackdrop';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CopcoinLogoIcon from '../../logos/icon-copcoin.png';

import { getApi } from '../api/apiManager';

const validateEmailInput = (input) => {
  return /^([A-Za-z0-9\-_]+\.?)+@[A-Za-z0-9]+\.([A-Za-z]+\.?)+$/.test(input);
};

export default function Login({ triggerSessionValidation, setAuthState, setLocalUser }) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
    setOpenWarning(false);
  };

  const login = async () => {
    try {
      setOpenLoading(true);
      const result = await Auth.signIn(email, password);
      console.log(email, password);
      console.log(result);
      setLocalUser(result);
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') setAuthState('CHANGE_PASSWORD');
      else triggerSessionValidation();
    } catch (err) {
      switch (err.code) {
        case 'NotAuthorizedException':
          setErrorMessage('Las credenciales son incorrectas, intenta de nuevo.');
          setOpenError(true);
          break;
        case 'UserNotFoundException':
          setErrorMessage('El usuario especificado no existe.');
          setOpenError(true);
          break;
        case 'UserNotConfirmedException':
          setErrorMessage('Verifica tu cuenta antes de iniciar sesión.');
          setOpenError(true);
          break;
        default:
          break;
      }
      console.log(err);
    } finally {
      setOpenLoading(false);
    }
  };

  const handleEmail = (event) => {
    const error = !validateEmailInput(event.target.value);
    setEmailError(error);
    setEmail(event.target.value);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <LoadingBackdrop open={openLoading} />
      <div className={classes.paper}>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='error'>
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={openWarning} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='warning'>
            Ingresa un correo válido y tu contraseña
          </Alert>
        </Snackbar>
        <Card>
          <CardContent>
            <img
                src={CopcoinLogoIcon}
                alt='Logo Copcoin'
                width='90'
                height='auto'
                id='homeButton'
              />
            <TextField
              type='email'
              variant='outlined'
              margin='normal'
              fullWidth
              label='Correo electrónico'
              name='email'
              autoComplete='email'
              error={emailError}
              helperText={emailError ? 'Ingresa un correo válido' : null}
              autoFocus
              value={email}
              onChange={handleEmail}
            />
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              name='password'
              label='Contraseña'
              type='password'
              id='password'
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete='current-password'
            />
            <Button
              fullWidth
              color='primary'
              className={classes.forgotPasswordButton}
              onClick={() => setAuthState('FORGOT_PASSWORD')}
              id='forgotPasswordButton'>
              Olvidé mi contraseña
            </Button>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={() => {
                return !emailError && login();
              }}
              id='logInButton'>
              Ingresar
            </Button>
            <Button
              fullWidth
              variant='outlined'
              color='primary'
              className={classes.createAccount}
              onClick={() => setAuthState('SIGNUP')}
              id='signupButton'>
              Crear una cuenta
            </Button>
          </CardContent>
        </Card>
      </div>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  createAccount: {
    margin: theme.spacing(0, 0, 2)
  },
  forgotPasswordButton: {
    textAlign: 'right'
  }
}));

Login.propTypes = {
  triggerSessionValidation: PropTypes.func,
  setAuthState: PropTypes.func,
  setLocalUser: PropTypes.func
};
