import  CopcoinABI  from  "../../../src/contracts/Copcoin.json";

const contractAddress = '0x683fe7b09b37731fe0e17543dd3646ca30ae7189';

function getContract(web3){
    return new Promise(async (resolve, reject) => {
        try{
            const contract = new web3.eth.Contract(CopcoinABI.abi, contractAddress);
            resolve(contract);
        }
        catch (e){
            console.log(e);
            reject('Error conectando con el contrato');
        }
    })
}

function getOwnerAddress (contract){
    return new Promise(async function(resolve, reject) {
        contract.methods.getOwner().call()
        .then((result) => {
            resolve(result);
        })
        .catch(e => {
            reject('Error consultando el dueño del contrato');
        });
    })
}

export const balanceOf = async (web3, address) => {
    return new Promise((resolve, reject) => {
        getContract(web3)
        .then(async (contract) => {
            contract.methods.balanceOf(address).call().
            then((result) => {
                resolve(result);
            })
            .catch(e => {
                throw new Error('Error consultando el saldo');
            });
        })
        .catch(e => {
            console.log(e);
            reject(e);
        });;
    })
}

export const totalSupply = async (web3) => {
    return new Promise((resolve, reject) => {
        getContract(web3)
        .then(async (contract) => {
            contract.methods.totalSupply().call()
            .then((result) => {
                resolve(result);
            })
            .catch(e => {
                console.log(e);
                reject('Error consultando el saldo en circulación');
            });
            
        })
        .catch(e => {
            reject(e);
        });;
    });
}

export const buy = async (web3, address, ammount) => {
    return new Promise((resolve, reject) => {
        getContract(web3)
        .then(async (contract) => {
            getOwnerAddress(contract)
            .then(result => {
                if(result !== address){
                    //contract.methos.approve()
                }
                else{
                    reject("Usted es el dueño, no puede comprar.");
                }
            })
            .catch(e => {
                reject(e);
            })
            
        })
        .catch(e => {
            reject(e);
        });
    });
}

export const redeem = async (web3, ammount, address) => {
    return new Promise((resolve, reject) => {
        getContract(web3)
        .then(contract => {
            getOwnerAddress(contract)
            .then(owner => {
                contract.methods.transfer(owner, ammount).send({from: address})
                .then(result => {
                    resolve(result);
                })
                .catch(e => {
                    console.log(e.message);
                    reject("No se pudo liquidar el monto.");
                });
            })
            .catch(e => {
                reject(e);
            })
        })
        .catch(e => {
            reject(e);
        });
    });
}

export const transfer = async (web3, ammount, address, to) => {
    return new Promise((resolve, reject) => {
        getContract(web3)
        .then(contract => {
            contract.methods.transfer(to, ammount).send({from: address})
            .then(result => {
                resolve(result);
            })
            .catch(e => {
                console.log(e.message);
                reject("No se pudo liquidar el monto.");
            });
        })
        .catch(e => {
            reject(e);
        });
    });
}