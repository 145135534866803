import {
  AppBar,
  Card,
  CardContent,
  Container,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
  Button,
  Checkbox, 
  FormControlLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Collapse from '@material-ui/core/Collapse';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import CloseIcon from '@material-ui/icons/Close';
import SuccessSnackbar from '../../common/SuccessSnackbar';
import ErrorSnackbar from '../../common/ErrorSnackbar';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import PropTypes from 'prop-types';

import React, { useEffect, useState , useCallback } from 'react';
import { postApi } from '../../api/apiManager';
import { getApi } from '../../api/apiManager';
import {
  validateEmailInput,
  validateNumericInput,
  validateMoneyInput
} from '../../../controllers/validators';
import CopcoinLogoIcon from '../../../logos/icon-copcoin.png';

export default function BuySell({ open }) {
  const classes = useStyles();

  const [userData, setUserData] = useState({
    quantity: 0,
    selectedAsset: ''
  });
  const [dataErrors, setDataErrors] = useState({
    name: false,
    email: false,
    organization: false,
    kycPlan: false,
    carlaidPlan: false,
    contractsPlan: false,
    roles: false
  });
  const [selectedAsset, setSelectedAsset] = useState({
    name:'',
    lastTradePrice:''
  });
  const [buyingInfo, setBuyingInfo] = useState({
    value:'',
    quantity:'',
    tradePrice:''
  });
  const [fiats, setFiats] = useState([]);

  const requiredFields = 2;
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  //Disable the Autocompletes for companies

  const [completedFields, setCompletedFields] = useState(0);

  const updateCompletedFieldCount = useCallback(() => {
    let completed = 0;
    if (userData.name) completed++;
    if (userData.code) completed++;
    setCompletedFields(completed);
  }, [userData]);

  //Api Calls
  const getParams = async () => {
    try {
      const data = await getApi(`activos/params`);
      const coins = data.coins.filter((c)=>{return c.is_digital_asset});
      setFiats(coins);
    } catch (err) {
      setErrorMessage('No fue posible cargar la información de la base de datos.');
    }
  };

  useEffect(() => {
    updateCompletedFieldCount();
  }, [updateCompletedFieldCount])

  useEffect(() => {
    getParams()
  }, [open]);

  const handleCloseError = () => {
    setOpenError(false);
    setErrorMessage('');
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    setSuccessMessage('');
  };

  const handleAttributeChange = (name, value) => {
    setUserData((previous) => {
      const newObject = { ...previous };
      newObject[name] = value;
      return newObject;
    });
  };

  const handleDigitalAsset = () => {
    setUserData((previous) => {
      const newObject = { ...previous };
      newObject.is_digital_asset = !previous.is_digital_asset
      return newObject;
    });
  }

  const getAssetInfo = async function (asset){
    if(asset !== null && asset !== undefined){
      try {
        let result = await postApi('kraken/Ticker', {
          pair: asset.short_name.concat('USD')
        });
        result = result.result;
        let data = result[Object.keys(result)[0]]
        setSelectedAsset((previous) => {
          const newObject = { ...previous };
          newObject["lastTradePrice"] = data.c[0];
          return newObject;
        });
        

      } catch (error) {
        console.log(error);
      }
    }
    else{
      setSelectedAsset({
        name:'', 
        lastTradePrice:''
      });
      console.log(selectedAsset)
    }
  }

  const handleAsset = (event, value) => {
    getAssetInfo(value);
    setSelectedAsset({value: value})
  }; 

  const handleBoughtInfoChange = (name, value) => {
    console.log(value);
    setBuyingInfo((previous) => {
      const newObject = { ...previous };
      newObject[name] = value;
      if(name == 'tradePrice' && previous.value){
        newObject.quantity = previous.value/value;
      }
      else if(name == 'value' && previous.tradePrice){
        newObject.quantity = value/previous.tradePrice
      }
      else if(name == 'quantity' && previous.tradePrice){
        newObject.value = value * previous.tradePrice;
      }
      return newObject;
    });
  };

  const handleClose = () => {
    setUserData((previous) => {
      const newObject = { ...previous };
      for (let key in newObject) {
        if (key === 'roles') newObject[key] = [];
        else newObject[key] = '';
      }
      return newObject;
    });
  };

  return (
    <div className={classes.root}>
      <Container component='main' maxWidth='md'>
        <LoadingBackdrop open={openBackdrop} />
        <ErrorSnackbar open={openError} message={errorMessage} handleClose={handleCloseError} id='errorSnackbar' />
        <SuccessSnackbar
          open={openSuccess}
          message={successMessage}
          handleClose={handleCloseSuccess}
          id='successSnackbar'
        />
        <Grid container spacing={10} alignItems="center" justify="center" className={classes.container}>
          <Grid item xs={7}>
            <Card>
              <CardContent>
                <img
                  src={CopcoinLogoIcon}
                  alt='Logo Copcoin'
                  width='90'
                  height='auto'
                  id='homeButton'
                />
                <Autocomplete
                  required
                  noOptionsText="No hay opciones"
                  options={fiats}
                  getOptionLabel={(option) => option.short_name}
                  onChange={handleAsset}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="selectedAsset"
                    variant='outlined'
                    margin='normal'
                    value={selectedAsset.value || ''}
                    label="Seleccione el activo a usar"
                    fullWidth
                  />
                )}
                />
                <TextField
                  id="quantity"
                  type="number"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  name="quantity"
                  label="Cantidad de Activos a comprar"
                  required
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  helperText={dataErrors.quantity && 'Revisa que sea un número válido'}
                  error={dataErrors.quantity}
                  value={buyingInfo.quantity || ''} 
                  onChange={(event) => handleBoughtInfoChange('quantity', event.target.value)}
                />
                <Button
                  fullWidth
                  variant='contained' 
                  color='primary'
                  className={classes.submit}
                  onClick={() => {
                    console.log("Clicked")
                  }}
                  id='buyButton'
                  >
                  Comprar
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={5} >
              <Card>
                <CardContent>
                  <Typography
                    margin='normal'
                    fullWidth
                    component='h5' variant='h5'
                  >
                    Resumen de compra
                  </Typography>
                  <Grid className={classes.container}>
                    <Grid item xs={8}>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        Tasa:
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        Costos de compra:
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        Costos de transacción:
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        Gas requerido:
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        Costos financieros:
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                        component='h6' variant='h6'
                      >
                        Total en COPC:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        0
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        0
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        0
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        0
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                      >
                        0
                      </Typography>
                      <Typography
                        margin='normal'
                        fullWidth
                        component='h6' variant='h6'
                      >
                        0
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: '1% 3% 8%',
    [theme.breakpoints.down('xs')]: {
      width: '82%'
    }
  },
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  container: {
    display:'flex', 
    justifyContent:'left'
  },
  progress: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(1)
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

BuySell.propTypes = {
  open: PropTypes.bool
};
