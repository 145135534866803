import React, { useState } from 'react';
import { validateAlphabeticInput, validateEmailInput, validateNonEmptyInput } from '../../controllers/validators';

import Alert from '@material-ui/lab/Alert';
import { Auth } from 'aws-amplify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ErrorSnackbar from '../common/ErrorSnackbar';
import LoadingBackdrop from '../common/LoadingBackdrop';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { postApi } from '../api/apiManager';

export default function Signup({ setAuthState, handleNotify }) {
  const classes = useStyles();
  const [data, setData] = useState({ email: '', password: '', name: '', organization: '', confirmPassword: '' });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    name: false,
    organization: false,
    confirmPassword: false
  });
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openLoading, setOpenLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const handleAttributeChange = (name, value) => {
    setData((previous) => {
      const newObject = { ...previous };
      newObject[name] = value;
      return newObject;
    });
    setErrors((previous) => {
      const newObject = { ...previous };
      if (name in validators) newObject[name] = !validators[name](value);
      else if (name === 'confirmPassword') newObject[name] = value !== data.password;

      return newObject;
    });
  };

  const existErrors = () => {
    for (let key in errors) {
      if (errors[key]) return true;
    }
    let error = false;
    for (let key in data) {
      if (key in validators) {
        error = error || !validators[key](data[key]);
      }
    }
    error = error || data.confirmPassword !== data.password;
    return error;
  };

  const signup = async () => {
    if (!existErrors()) {
      try {
        setOpenLoading(true);
        const result = await postApi(
          'user/signup',
          {
            username: data.email,
            password: data.password,
            name: data.name,
            'custom:organization': data.organization
          },
          null,
          true
        );
        if (!result) throw new Error();
        if (result.code) {
          switch (result.code) {
            case 'UsernameExistsException':
              setErrorMessage('Un usuario ya existe con el correo especificado');
              setOpenError(true);
              break;
            case 'OrganizationNotValidException':
              setErrorMessage('La organización especificada ya existe');
              setOpenError(true);
              break;
            default:
              setErrorMessage('Ha ocurrido un error, revisa todos los campos e intenta nuevamente');
              setOpenError(true);
              break;
          }
        } else {
          handleNotify(
            'El usuario fue creado exitosamente, verifica tu correo para validar tu cuenta e ingresa tu correo y contraseña para entrar'
          );
          setAuthState('LOGIN');
        }
      } catch (err) {
        setErrorMessage('Ha ocurrido un error creando al usuario. Intenta de nuevo más tarde');
        setOpenError(true);
        console.log('ERROR:', err);
      } finally {
        setOpenLoading(false);
      }
    } else {
      setErrorMessage('Revisa que los campos estén correctos e intenta nuevamente.');
      setOpenError(true);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <LoadingBackdrop open={openLoading} />
      <div className={classes.paper}>
        <ErrorSnackbar open={openError} duration={6000} handleClose={handleClose} message={errorMessage} />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Creación de cuenta
        </Typography>
        <TextField
          type='text'
          variant='outlined'
          margin='normal'
          fullWidth
          label='Nombres y apellidos'
          name='name'
          autoComplete='names'
          error={errors.name}
          helperText={errors.name ? 'Ingresa un nombre válido' : null}
          autoFocus
          value={data.name}
          onChange={(event) => handleAttributeChange(event.target.name, event.target.value)}
        />
        <TextField
          type='text'
          variant='outlined'
          margin='normal'
          fullWidth
          label='Organización'
          name='organization'
          autoComplete='organization'
          error={errors.organization}
          helperText={errors.organization ? 'Ingresa un nombre válido' : null}
          autoFocus
          value={data.organization}
          onChange={(event) => handleAttributeChange(event.target.name, event.target.value)}
        />
        <TextField
          type='email'
          variant='outlined'
          margin='normal'
          fullWidth
          label='Correo electrónico'
          name='email'
          autoComplete='email'
          error={errors.email}
          helperText={errors.email ? 'Ingresa un correo válido' : null}
          autoFocus
          value={data.email}
          onChange={(event) => handleAttributeChange(event.target.name, event.target.value)}
        />

        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          name='password'
          label='Contraseña'
          type='password'
          id='password'
          value={data.password}
          onChange={(event) => handleAttributeChange(event.target.name, event.target.value)}
          autoComplete='current-password'
        />
        <TextField
          variant='outlined'
          margin='normal'
          fullWidth
          required
          name='confirmPassword'
          label='Confirmar Nueva Contraseña'
          type='password'
          id='confirmPassword'
          error={errors.confirmPassword}
          helperText={errors.confirmPassword ? 'La contraseña no coincide con la especificada.' : null}
          value={data.confirmPassword}
          onChange={(event) => handleAttributeChange(event.target.name, event.target.value)}
          autoComplete='password'
        />
        <Button
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={signup}
          id='logInButton'>
          Crear cuenta
        </Button>
        <Button
          fullWidth
          color='primary'
          className={classes.forgotPasswordButton}
          onClick={() => setAuthState('LOGIN')}
          id='forgotPasswordButton'>
          Ya tengo una cuenta
        </Button>
      </div>
    </Container>
  );
}

const validators = {
  email: validateEmailInput,
  name: validateAlphabeticInput,
  organization: validateNonEmptyInput,
  password: validateNonEmptyInput
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  forgotPasswordButton: {
    textAlign: 'right'
  }
}));

Signup.propTypes = {
  setAuthState: PropTypes.func,
  handleNotify: PropTypes.func
};
