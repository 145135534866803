export default {
  primary: '#FF3F00',
  primaryTransparent: 'rgba(0,135,230,0.8)',
  secondary: '#FF936F',
  accent: '#dd546e',
  white: '#fff',
  mediumGray: '#999',
  gray: '#5c5c5c',
  lightGray: '#f0f0f0',
  grayTransparent: 'rgba(0,0,0,0.2)',
  yellow: '#ffc800',
  bancoldexGray: '#EFEFEF',
  bancoldexFontColor: '#2e3130',
  black: '#000',
  transparent: 'rgba(0,0,0,0)',
};
