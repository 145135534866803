/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:cfc5ee41-3152-4178-8531-932e677bf6c6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SSNjfVA99",
    "aws_user_pools_web_client_id": "49soaclv7enehiikt8mg3h32m7",
    "oauth": {}
};


export default awsmobile;
